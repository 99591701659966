import { CommonModule, NgClass } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { BaseComponent } from '../base/base.component';
import { SurveyFormBannerPresenter } from './presenter/survey-form-banner.presenter';
import { SurveyFormBannerSideEffect } from './presenter/survey-form-banner.side-effect';
import { SurveyFormBannerViewEvent } from './presenter/survey-form-banner.view-event';
import { SurveyFormBannerViewState } from './presenter/survey-form-banner.view-state';

@Component({
  selector: 'app-survey-form-banner',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgClass, LottieComponent],
  templateUrl: './survey-form-banner.component.html',
  styleUrls: ['./survey-form-banner.component.scss'],
  providers: [SurveyFormBannerPresenter],
})
export class SurveyFormBannerComponent extends BaseComponent<
  SurveyFormBannerPresenter,
  SurveyFormBannerViewState,
  SurveyFormBannerViewEvent,
  SurveyFormBannerSideEffect
> {
  @Input() addMarginTop: boolean;

  public presenter: SurveyFormBannerPresenter = inject(SurveyFormBannerPresenter);

  options: AnimationOptions = {
    path: '/assets/animations/arrow.json',
  };

  ngOnInit(): void {
    super.ngOnInit();
  }

  onSideEffect(sideEffect: SurveyFormBannerSideEffect): void {
    switch (sideEffect.type) {
      case 'NavigateToForm': {
        window.open(sideEffect.link, '_blank', 'noopener,noreferrer');
      }
    }
  }
}
