import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { API_URLS } from 'src/app/presentation/shared/constants';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  generateReferralCode(user: any): Observable<any> {
    return this.http.post(API_URLS.GENERATE_REFERRAL_CODE_URL, user);
  }

  getAnnouncement(): Observable<any> {
    return this.http.get(API_URLS.GET_ANNOUNCEMENT_URL);
  }
}
